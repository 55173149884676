<template>
  <div class="home">
    <el-container>
      <el-header el-headeclass="homeHeader">
        <Header></Header>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <div class="footer-box">
          <div class="footer-name">
            AECU代驾中国
          </div>
          <div class="footer-tag flex-row-between">
            <div class="hover-pointer">
              合作伙伴
            </div>
            <div class="line">
              |
            </div>
            <div class="hover-pointer">
              购车指南
            </div>
            <div class="line">
              |
            </div>
            <div class="hover-pointer">
              租车/租电
            </div>
            <div class="line">
              |
            </div>
            <div class="hover-pointer">
              隐私&法律
            </div>
            <div class="line">
              |
            </div>
            <div class="hover-pointer" @click="nav">
              网站地图
            </div>
          </div>

        </div>
        <div class="footer-code">
          蜀ICP备15044878号-5 蜀公网安备 31011402003368号
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/header/header.vue'

export default {
  name: 'HomeView',
  components: {
    Header
  },
  mounted() {
    console.log('mountedhome')
    this.$nextTick(()=>{
      // window.scrollTo(0, 0)
    })
  },
  methods:{
    nav(){
      this.$router.push({ path: '/webRouter'});
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  .footer-box {
    padding: 40px 80px;
    box-sizing: border-box;
    background-color: #fff;
    color: #061826;
    position: relative;

    .footer-name {
      position: absolute;
      left: 80px;
      top: 40px;
      font-size: 16px;
      font-weight: bold;
    }

    .footer-tag {
      font-size: 16px;
      width: 540px;
      margin: 0 auto 0px;

      .line {
        padding: 0 15px;
        color: #BFBFBF;
      }
    }

  }
}

.footer-code {
  font-size: 16px;
  padding-top: 24px;
  padding-bottom: 40px;
  border-top: 1px solid #F6F8FB;
  color: #7b7b7b;
}

.el-main {
  padding: 0;
}

.el-header {
  height: 0 !important;
}

.el-footer {
  padding: 0;
}

.homeHeader {
  position: sticky;
  top: 0;
}
</style>
