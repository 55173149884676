import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const store = new Vuex.Store({
    state:{
        handleLoading:true,
        menuList:{}
    },
    getters:{

    },
    actions:{

    },
    mutations:{
        SET_HANDLELOADING: (state, data) => {
            // console.log(Vue)
            state.handleLoading = data;
        },
        SET_MENU_LIST: (state, data) => {
            state.menuList = data;
        },
    }
}) 

export default store;