import axios from 'axios'
import qs from 'qs'
import store from '../store/index'
axios.defaults.baseURL = '/api/'
var instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'tenant-id':121,
  },
  timeout: 20000,
  baseURL: 'https://electric.aidjr.com'
  // baseURL: 'http://192.168.3.40:9021'
})
instance._requestCount = 0;
instance._requestTimeIdList = [];
const pending = []
const cancelToken = axios.CancelToken   
const clearTimeoutByUrl = (url, requestList) => {
  for (let item in requestList) {
      if (url === requestList[item]['url']) {
          clearTimeout(requestList[item]['timeId']);
      }
  }
}

const removeRepeatUrl = (config) => {
  // eslint-disable-next-line no-undef
  const comValue = config.method === 'get' ? qs.stringify(config.params) : qs.stringify(config.data)
  for (const p in pending) {
    if (pending[p].u === config.url + '&' + config.method + '&' + comValue) {
      pending[p].f()
      pending.splice(p, 1)
    }
  }
}
const saveRepeatUrl = (config) => {
  // eslint-disable-next-line no-undef
  const comValue = config.method === 'get' ? qs.stringify(config.params) : qs.stringify(config.data)
  // eslint-disable-next-line new-cap
  config.cancelToken = new cancelToken((c) => {
    pending.push({ u: config.url + '&' + config.method + '&' + comValue, f: c })
  })
}

instance.interceptors.request.use(config => {
  instance._requestCount++;
  const timeId = setTimeout(() => {
       store.commit('SET_HANDLELOADING', true);
  }, 200);
  instance._requestTimeIdList.push({
      timeId: timeId,
      url: config.url
  });
  removeRepeatUrl(config) 
  saveRepeatUrl(config)
  return config
}, error => {
  console.log(error)
  return Promise.reject(error)
})


instance.interceptors.response.use(response => {
  instance._requestCount--;
  clearTimeoutByUrl(response.config.url, instance._requestTimeIdList);
  if (instance._requestCount <= 0) {
       store.commit('SET_HANDLELOADING', false);
  }
  removeRepeatUrl(response.config) 
  const res = response.data;

  return response
}, error => {
  instance._requestCount--;
  clearTimeoutByUrl(error.config.url, instance._requestTimeIdList);
  if (instance._requestCount <= 0) { 
      store.commit('SET_HANDLELOADING', false);
  }
  console.log('err' + error)
  return Promise.reject(error)
})

export default instance

/**
 * 
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(response => {
      resolve(response.data)
    }, err => {
      this.$store.commit('SET_HANDLELOADING', false);
      reject(err)
    })
  })
}

/**
 * 
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, {
      params: data
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        this.$store.commit('SET_HANDLELOADING', false);
        reject(err)
      })
  })
}

/**
 * 
 * @param methed
 * @param url
 * @param data
 * @param headers
 * @returns {Promise}
 */
export function request(methed, url, data = {}, headers) {
  return new Promise((resolve, reject) => {
    instance({
      method: methed || 'post',
      url: url,
      params: methed === 'get' ? data : '',
      data: methed !== 'get' ? data : '',
      headers: headers || { 'Content-Type': 'application/json' }
    })
      .then(response => {
        
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
