import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/axios/axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "amfe-flexible"
// import animated from "animate.css";
import 'wowjs/css/libs/animate.css'
// import wow from 'wow.js'
import VueVideoPlayer from 'vue-video-player/src'
// require videojs style
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
 
Vue.use(VueVideoPlayer)


Vue.use(ElementUI)
// Vue.use(animated)
Vue.prototype.$axios = axios
Vue.prototype.$store = store
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
