<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
   
  }
}
</script>
<style lang="scss">

@import url(common/css/iconfont.css);
@import url(common/css/font/font.css);
@import url(common/css/common.css);

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 14px !important;
}

body::-webkit-scrollbar { height: 0 !important;width:0px !important; }
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
