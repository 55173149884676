<template>
  <div class="header" :style="{ color: isAbout || isCheckMenu ? '#fff' : '#000' }" v-if="headerShow"
    :class="headerScroll ? 'headerAnimation' : 'headerEndAnimation'">
    <div class="flex p-relative"
      :class="headerScroll && !isCheckMenu ? 'box' : isCheckMenu && headerScroll ? 'checkBox' : ''">
      <div class="menu-wrapper" ref="parent">
        <div class="menu" :style="{ transform: 'translateX(' + -scrollLeft + 'px)' }" @mousedown.stop="startDrag"
          ref="menu">
          <div class="menuItem" :style="getChildStyle(item)" v-for="(item, index) in menuList" :key="index">
            <div :class="activeIndex === index ? 'text-c54' : ''" @click="clickMenu(item, index)">{{ item.name }}</div>
          </div>
          <div>
          </div>
        </div>
      </div>
      <div class="flex-sub">
        <div @click="toIndex" class="p-absolute headerTitle">
          智能代驾人
        </div>
      </div>
      <div class="subBtn" :style="{ borderColor: isAbout ? '#fff' : '#000' }">立即订购</div>
    </div>
    <div class="menu-child-box" v-if="isCheckMenu">
      <div class="menu-child-itemBox flex-column-between">
        <div class="child-check-item flex hover-pointer"
          :class="checkChild.name === item.name ? 'child-check-checkItem' : ''" @click="checkchildren(item, index)"
          @mouseover="mouseOver(item)" v-for="(item, index) in children" :key="index">
          <div class="child-check-item-name">
            {{ item.name }}
          </div>
          <div class="margin-left" v-show="checkChild.name === item.name">
            <i class="el-icon-arrow-right text-16 text-bold"></i>
          </div>
        </div>
      </div>
      <i v-if="isCheckMenu" @click="closeMenu" class="iconfont icon-close text-26 text-white close"></i>
    </div>
    <div class="menu-mask" v-if="isCheckMenu">
      <img :src="menuImg" />
    </div>
  </div>
</template>
<script>
import throttle from "@/utils/throttle"
export default {
  data() {
    return {
      activeIndex: '',
      headerScroll: false,
      isCheckMenu: false,
      headerShow: false,
      isAbout: true,
      menuList: [
        {
          title: '关于AECU',
          url: '/richText'
        },
        {
          title: '代驾车型',
          children: [
            {
              name: '小神牛i600',
              check: false
            },
            {
              name: '小神牛i700',
              check: false
            },
            {
              name: '小神牛i800',
              check: false
            }
          ]
        },
        {
          title: '代驾智能行',

        },
        {
          title: '代驾生活会',
          url: '/public'
        },
      ],
      children: [],
      checkChild: {},
      menuImg: require('../../static/home/Rectangle 32.png'),
      isDragging: false, // 是否正在拖拽
      startX: 0, // 鼠标按下时的X坐标
      startScrollLeft: 0, // 元素的初始滚动位置
      scrollLeft: 0,
      visibleChildren: [],//显示出来的菜单子元素
      halfVisibleChildren: [],//显示出来一半的子元素
      hiddenChildren: [],//完全显示出来的子元素
      childRects: [],
      grandparentWidth: null
    };
  },
  created() {
    console.log(this.$route.path);
    if (this.$route.path != '/about') {
      this.isAbout = false;
    }
  },
  mounted() {
    this.getMenuList()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    '$route': function (to, from) {
      console.log(to, from);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      if (this.$route.path === '/about') {
        this.isAbout = true;
      } else {
        this.isAbout = false;
      }

    }
  },
  methods: {
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.startScrollLeft = this.scrollLeft;
      document.addEventListener('mousemove', this.dragging);
      document.addEventListener('mouseup', this.stopDrag);
    },
    dragging(event) {
      if (this.isDragging) {
        const diffX = event.clientX - this.startX;
        const newScrollLeft = this.startScrollLeft - diffX;
        this.scrollLeft = newScrollLeft;
        this.scrollLeft = this.scrollLeft <= 0 ? 0 : this.scrollLeft >= 146 ? 146 : this.scrollLeft;
        this.menuHandleScroll()
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.dragging);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    mouseOver(item) {
      if (item.menuImg) this.menuImg = item.menuImg;
      if (!item.menuImg) this.menuImg = require('../../static/home/Rectangle 1 (2).png');
    },
    getChildStyle(item) {
      const childRect = this.childRects.find(rect => rect.el.textContent === item)
      if (!childRect) {
        return {}
      }

      return {
        position: 'absolute',
        top: `${childRect.top}px`,
        left: `${childRect.left}px`
      }
    },
    getMenuList() {
      this.$axios
        .get(`/admin-api/sale/common/menu/list`, {})
        .then((reserved) => {
          console.log(reserved);
          this.menuList = reserved.data.data;
          this.$store.commit('SET_MENU_LIST', this.menuList);
          this.$nextTick(() => {
            this.headerShow = true;
            window.addEventListener('scroll', this.handleScroll, true);
            setTimeout(() => {
              const parentEl = this.$refs.menu
              const childrenEl = parentEl.children

              const childRects = Array.from(childrenEl).map(childEl => {
                const childRect = childEl.getBoundingClientRect()
                const childStyle = window.getComputedStyle(childEl)
                const marginLeft = parseFloat(childStyle.marginLeft)
                const marginRight = parseFloat(childStyle.marginRight)
                const parentRect = parentEl.getBoundingClientRect()
                const width = childRect.width - marginLeft - marginRight
                return {
                  el: childEl,
                  top: childRect.top - parentRect.top,
                  left: childRect.left - parentRect.left,
                  width: width
                }
              })

              this.childRects = childRects

              const grandparentEl = this.$refs.parent
              const grandparentRect = grandparentEl.getBoundingClientRect()
              this.grandparentWidth = grandparentRect.width

              this.menuHandleScroll()
            }, 0);
            // new this.$wow.WOW().init();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /***禁止滚动***/
    stopScroll() {
      let mo = function (e) { e.preventDefault(); };
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", mo, false);//禁止页面滑动
    },
    /***允许滚动***/
    canScroll() {
      let mo = function (e) { e.preventDefault(); };
      document.body.style.overflow = '';//出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    checkchildren(item, index) {
      this.checkChild = this.children.find((it, ins) => index == ins);
      if (this.checkChild.path && this.$route.path.indexOf('richText') != -1) {
        this.closeMenu();
        this.$router.replace({ path: this.checkChild.path, query: { id: item.id } });
      } else if (this.checkChild.path && this.$route.path.indexOf('richText') == -1) {
        this.closeMenu();
        this.$router.push({ path: this.checkChild.path, query: { id: item.id } });
      }
      console.log(this.checkChild)
    },
    closeMenu() {
      this.isCheckMenu = false;
      this.canScroll();
    },
    toIndex() {
      this.activeIndex = '';
      this.$router.push({ path: '/' });
    },
    clickMenu(item, index) {
      if (item.children) {
        this.children = item.children;
        this.isCheckMenu = true;
        this.stopScroll();
      } else if (item.path) {
        this.closeMenu()

        this.$router.replace({ path: item.path, query: { pathData: encodeURIComponent(JSON.stringify(item)) } });
      } else {
        // this.isCheckMenu = false;
        this.closeMenu()
        this.children = []
      }
      this.activeIndex = index;
      this.scrollLeft = this.$refs.menu.children[index].offsetLeft - (window.innerWidth - this.$refs.menu.children[index].offsetWidth) / 6;
      this.scrollLeft = this.scrollLeft <= 0 ? 0 : this.scrollLeft >= 146 ? 146 : this.scrollLeft;
    },
    handleSelect: throttle(function (key, keyPath) {
      console.log(key, keyPath);
    }, 500),
    //页面滚动事件
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // var offsetTop = document.querySelector('.header').offsetTop // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
      // console.log(scrollTop,offsetTop)
      if (scrollTop == 0) {
        this.headerScroll = false;
      } else {
        this.headerScroll = true;
      };

    },
    menuHandleScroll() {
      const parentEl = this.$refs.parent
  const parentRect = parentEl.getBoundingClientRect()

  this.childRects.forEach(childRect => {
    const childEl = childRect.el
    const childRect2 = childEl.getBoundingClientRect()

    const leftDistance = childRect2.left - parentRect.left
    const rightDistance = parentRect.right - childRect2.right

    if ((leftDistance <= parentRect.width && rightDistance <= parentRect.width) && (leftDistance >= -childRect.width && rightDistance >= -childRect.width)) {
      childEl.style.visibility = 'visible'
    } else {
      childEl.style.top = `${childRect.top}px`
      childEl.style.left = `${childRect.left}px`
      childEl.style.visibility = 'hidden'
    }
  })
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  color: #fff;
  font-size: 16px;

  .menu-child-box {
    width: 794px;
    height: 100%;
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    background: rgba(29, 29, 29, 0.96);
    moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .menu-child-itemBox {
      padding: 104px 80px;

      .child-check-item {
        // width: 150px;
        line-height: 60px;

      }

      .child-check-item-name {
        border-bottom: 1px solid #00000000;
      }

      .child-check-item-name:hover {
        border-bottom: 1px solid #54D375;
      }

      .child-check-checkItem {
        color: #54D375;
      }
    }
  }

  .menu-mask {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .menu-wrapper {
    line-height: 32px;
    // overflow: auto;
    width: 520px;
  }

  .menu-wrapper::-webkit-scrollbar {
    height: 0 !important;
    width: 0px !important;
  }

  .menu {
    position: relative;
    z-index: 999;
    line-height: 32px;
    // height: 32px;
    display: flex;
    white-space: nowrap;
    transition: transform 0.3s;
  }

  .menu::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    background: transparent;
  }

  .menuItem {
    display: inline-block;
    margin-right: 40px;
    // padding-bottom: 16px;
    font-size: 14px;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 1000;
    moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 1px solid #00000000;

  }

  .menuItem:hover {
    border-bottom: 1px solid #54D375;
  }

  .close {
    // padding-left: 200px;
    position: absolute;
    top: 32px;
    right: 44px;
    cursor: pointer;
  }

  .headerTitle {
    left: 50%;
    cursor: pointer;
    transform: translateX(-50%);
    line-height: 32px;
    font-size: 14px;
    moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.subBtn {
  // width: 96px;
  // height: 32px;
  cursor: pointer;
  padding: 0px 10px;
  font-size: 14px;
  line-height: 32px;
  border: 1px solid #FFFFFF;
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.subBtn:hover {
  background-color: #FFFFFF;
  color: #000;
  animation: subBtn-hover .3s ease-out 1;
}

@keyframes subBtn-hover {
  0% {
    background-color: transparent;
  }

  100% {
    color: #000;
    background-color: #FFFFFF;
  }
}

.box {
  height: auto;
}

.checkBox {
  padding: 16px 0px;
}

.box:hover {
  padding: 16px 0px;
  animation: haeder-hover .3s ease-out 1;
}

.box:not(:hover) {
  padding: 8px 0px;
  animation: haeder-notHover .3s ease-out 1;
}

.headerAnimation {
  animation: haeder-in .25s ease-out 1;
  background: rgba($color: #000000, $alpha: .6);
  color: #fff !important;
  padding: 8px 80px;

  .subBtn {
    border-color: #fff !important;
  }
}

.headerEndAnimation {
  animation: haeder-out .25s ease-out 1;
  background: transparent;
  padding: 32px 80px;
}



@keyframes haeder-in {
  0% {
    padding: 32px 80px;
    background: transparent;
  }

  50% {
    padding: 20px 80px;
    background: rgba($color: #000000, $alpha: .3);
  }

  100% {
    padding: 8px 80px;
    color: #fff;
    background: rgba($color: #000000, $alpha: .6);
  }
}

@keyframes haeder-out {
  0% {
    padding: 8px 80px;
    background: rgba($color: #000000, $alpha: .6);
  }

  50% {
    padding: 16px 80px;
    background: rgba($color: #000000, $alpha: .3);
  }

  50% {
    padding: 32px 80px;
    background: transparent;
  }
}

@keyframes haeder-hover {
  from {
    padding: 8px 0px;
  }

  to {
    padding: 16px 0px;
  }
}

@keyframes haeder-notHover {
  from {
    padding: 16px 0px;
  }

  to {
    padding: 8px 0px;
  }
}
</style>